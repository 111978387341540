// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { clientServiceState } from '../objects/clientServiceState';
import { AccommodationProductBulk } from '../../accommodation/objects/accommodationProductBulk';
import { User } from '../../user/objects/user';

const initialState: clientServiceState = {
    all_products: [],
    user_list: [],
    loading: false,
    user_count: 0,
    user_search: '',
    user_search_id: null
};

export const clientService = createSlice({
    name: 'clientService',
    initialState,
    reducers: {
        setAllProducts: (state, action: PayloadAction<AccommodationProductBulk[]>) => {
            const cart = [];
            action.payload.map(accommodation => {
                const hotel = {
                    blacklist: accommodation.blacklist,
                    city_name: accommodation.city_name,
                    country_code: accommodation.country_code,
                    cover_picture: accommodation.cover_picture,
                    custom_pictures: accommodation.custom_pictures,
                    favorite: accommodation.favorite,
                    hotel_sup: accommodation.hotel_sup,
                    house_number: accommodation.house_number,
                    id: accommodation.id,
                    latitude: accommodation.latitude,
                    longitude: accommodation.longitude,
                    name: accommodation.name,
                    pictures: accommodation.pictures,
                    remark_favorite: accommodation.remark_favorite,
                    stars: accommodation.stars,
                    street: accommodation.street,
                    zip_code: accommodation.zip_code
                };
                if (accommodation.products) {
                    accommodation.products.map(room => {
                        cart.push({...room, hotel: [hotel]});
                    });
                }
            });
            state.all_products = cart.sort((a, b) => {
                return b.trip.trip_reference - a.trip.trip_reference;
            });
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setUserList: (state, action: PayloadAction<{ user_list: User[], user_count: number }>) => {
            state.user_list = action.payload.user_list;
            state.user_count = action.payload.user_count;
        },
        setUserSearch: (state, action: PayloadAction<string>) => {
            state.user_search = action.payload;
        },
        setUserSearchId: (state, action: PayloadAction<number | null>) => {
            state.user_search_id = action.payload;
        }
    }
});


export const {
    setAllProducts,
    setLoading,
    setUserList,
    setUserSearch,
    setUserSearchId
} = clientService.actions;

export default clientService.reducer;